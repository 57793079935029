import { useIntl } from "react-intl";
import { FieldType, Schema } from "../../../../toolympus/hooks/useSchema";
import { useLoadedData } from "../../../../toolympus/hooks/useLoadedData";
import { useTextFilter } from "../../../../toolympus/components/schemed/Filtering/useTextFilter";
import { useEditItem2, useNewItem } from "../../../../toolympus/api/useNewItem";
import { useItemActionWithConfirmation } from "../../../../toolympus/api/useAction";
import { apiFetch } from "../../../../toolympus/api/core";

export interface Topic {
  _id: number;
  title: string;
  parent_id?: string | null;
  sort_order: number;
  created_by?: string;
  created_at?: string;
}

export const TopicSchema: Schema = {
  _id: { label: "ID", type: FieldType.number },
  sort_order: { label: "Sort order", type: FieldType.number },
  title: { label: "Title" },
  parent_id: { label: "Parent" },
  country: { label: "State", dictionary: "Countries", type: FieldType.dictionarySelect },
  created_by: { label: "Created by", dictionary: "Users", type: FieldType.dictionarySelect },
  created_at: { label: "Created at", type: FieldType.datetime },
}

const ApiPath = "/api/settings/topic";

export const useManageTopics = () => {
  const data = useLoadedData<Topic[]>(ApiPath, []);
  const filter = useTextFilter<Topic>(c => `${c.title} ${c._id}`);
  

  const newTopic = useNewItem<Omit<Topic, "_id">, Topic>(
    ApiPath,
    {
      title: "",
      sort_order: Math.max(...data.data.map(c => c.sort_order), 0) + 1,
    });

  const editTopic = useEditItem2<Topic>({
    getApiPath: c => `${ApiPath}/${c._id}`,
  });

  const { formatMessage } = useIntl();

  const removeTopic = useItemActionWithConfirmation<Topic, {}>(
    item => apiFetch<Topic>(`${ApiPath}/${item?._id}`, "delete")
      .then(x => {
        data.reload();
        return x;
    }),
    {
      title: formatMessage({ id: "dictionaries.remove_title" }),
      confirmationHint: formatMessage({ id: "dictionaries.remove_hint" }),
    }
  );

  return {
    ...data,
    data: filter.filterData(data.data),
    filter,
    schema: TopicSchema,

    newTopic: {
      ...newTopic,
      save: () => newTopic.save().then(c => { data.reload(); return c; }),
    },
    editTopic: {
      ...editTopic,
      save: () => editTopic.save().then(c => { data.reload(); return c; }),
    },
    removeTopic: removeTopic,
  }
}
