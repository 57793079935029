import { useState } from "react";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useFieldSorting } from "../../toolympus/hooks/useFieldSorting";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useNewItem } from "../../toolympus/api/useNewItem";

export interface CaseRecord {
  _id: number;
  title: string;
  case_date?: string;
  country?: string;
  decision_body?: string;
  topics?: string[] | null;
  source_url?: string;

  description?: any;
  content?: any;

  is_published?: boolean;
  
  owner_id?: string;
  created_at?: string;
  created_by?: string;
}

export type NewCaseRecord = Omit<CaseRecord, "_id">;

export const CaseRecordApiPath = "/api/case-record"

export const useCaseRecordSchema = () => {
  const { schema } = useSingleSchema(`${CaseRecordApiPath}/uiconfig`);
  return schema;
}

export const useCaseRecordsList = () => {
  const [view, setView] = useState<string>("unpublished");
  const sorting = useFieldSorting({
    disallowedFields: ["description"],
  });
  const data = useLoadedData<CaseRecord[]>(`${CaseRecordApiPath}?view=${view}&${sorting.queryParameter}`, []);
  const filter = useTextFilter<CaseRecord>(cr => `${cr.title} ${cr._id}`);

  const newRecord = useNewItem<NewCaseRecord, CaseRecord>(CaseRecordApiPath, { title: "" });

  return {
    ...data,
    data: filter.filterData(data.data),
    filter,
    view,
    setView,
    sorting,

    newRecord,
  }
}

export type CaseRecordListData = ReturnType<typeof useCaseRecordsList>;
