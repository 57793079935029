import React, { ReactNode, useState } from 'react';
import { useLoadedData } from '../../../toolympus/hooks/useLoadedData';
import { CaseRecord, CaseRecordApiPath, useCaseRecordSchema } from '../../CaseRecords/useCaseRecordsList';
import { Dialog, DialogState, EmptyState, FormGrid, InputIconButton } from '../../../toolympus/components/primitives';
import { Button, TextField } from '@mui/material';
import isHotkey from 'is-hotkey';
import { TableForFields } from '../../../toolympus/components/schemed';
import { Send } from '@mui/icons-material';

const useCaseRecordSearch = () => {
  const [search,setSearch] = useState<string>("");
  const [searchSubmitted,setSearchSubmitted] = useState<string>("");
  const data = useLoadedData<CaseRecord[]>(`${CaseRecordApiPath}?title_search=${searchSubmitted}`, [], !!searchSubmitted);

  const schema = useCaseRecordSchema();

  return {
    data,
    search,
    schema,
    isSubmitted: search === searchSubmitted,
    setSearch,
    submit: () => setSearchSubmitted(search),
  }
}


interface Props extends Omit<DialogState, "open"> {
  title: ReactNode;
  isLoading?: boolean;
  selectRecord: (cr: CaseRecord) => void;
}

export const CaseRecordSelectionDialog = (props: Props) => {
  const data = useCaseRecordSearch();

  const submit = () => {
    if(!data.isSubmitted && !data.data.isLoading) {
      data.submit();
    } else if(data.data.data.length === 1 && !props.isLoading) {
      props.selectRecord(data.data.data[0]);
    }
  }

  return (
    <Dialog
      dialogTitle={props.title}
      isOpen={props.isOpen}
      close={() => props.close()}
      noSubmitOnEnter
      noFullscreen
      closeButtonTop>
      <FormGrid columns="1fr">
        <TextField
          value={data.search}
          onChange={e => data.setSearch(e.target.value)}
          fullWidth
          autoFocus
          placeholder="Search..."
          onKeyPress={e => {
            if(isHotkey("enter")) {
              submit();
            }
          }}
          InputProps={{
            endAdornment: <InputIconButton icon={<Send />} position="end" action={() => submit()} />
          }}
          />

        {!data.data.data.length
          &&
          <EmptyState
            text="Nothing found"
            />}

        {!!data.data.data.length &&
          <TableForFields
            data={data.data.data}
            fields={[
              ["title"],
              ["is_published"]
            ]}
            schema={data.schema}
            rowButtons={r => (
              <Button size="small" color="primary" onClick={() => props.selectRecord(r)}>select</Button>
            )}
            />}

      </FormGrid>
    </Dialog>
  );
}
