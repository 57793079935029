import React from 'react';
import { RenderElementProps } from 'slate-react';
import { ParagraphElementType } from '../../../toolympus/components/PowerDoc/slate/BlockElements';
import { LinksBlips } from '../../Common/Links/LinksBlips';

export const ContentSubIdDataAttribute = "data-sub-id";

export const BlockElement = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  const style = { textAlign: element.align };
  const elementId = (element as any)._id;

  switch(element.type) {
    case ParagraphElementType:
        return <p style={style} {...attributes} data-sub-id={(element as any)._id}>
          <LinksBlips thisSubId={elementId} />
          {children}
        </p>
    default:
        const Wrapper = element.type as React.ElementType | string;
        if(Wrapper)
            return (
            <Wrapper style={style} {...attributes} data-sub-id={(element as any)._id}>
              <LinksBlips thisSubId={elementId} />
              {children}
            </Wrapper>);
        else
            return <></>;
  }
}
