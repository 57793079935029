import React from 'react';
import { FormGrid, SimpleDialog } from '../../../../toolympus/components/primitives';
import { DecisionBody, DecisionBodySchema } from './useManageDecisionBodies';
import { useItemWithControls } from '../../../../toolympus/api/useWithControls';
import { EditItemProps } from '../../../../toolympus/api/useNewItem';

interface Props extends Pick<EditItemProps<Partial<DecisionBody>>, "item" | "update" | "errors" | "save">{
  isOpen: boolean;
  close: () => void;
  isCreate?: boolean;
}

export const DecisionBodyEditDialog = (props: Props) => {
  const { isOpen, close, item, update, errors, save } = props;

  const { controls } = useItemWithControls({ data: item || {}, update, errors }, { schema: DecisionBodySchema });

  return (
    <SimpleDialog
      dialogTitle="Organ"
      submitOnModEnter
      close={close}
      isOpen={isOpen}
      save={save}
      maxWidth="md">

        <FormGrid columns="1fr 1fr">
          {item && controls([
              ["title", { controlProps: { autoFocus: true } }],
              ["title_short"],
              ["country", { autoComplete: true }],
              ["sort_order"],
              props.isCreate ? null : ["created_by", { disabled: true }],
              props.isCreate ? null : ["created_at", { disabled: true, utcToLocal: true }],
            ])}

        </FormGrid>
      
    </SimpleDialog >
  );
}
