import { useIntl } from "react-intl";
import { FieldType, Schema } from "../../../../toolympus/hooks/useSchema";
import { useLoadedData } from "../../../../toolympus/hooks/useLoadedData";
import { useTextFilter } from "../../../../toolympus/components/schemed/Filtering/useTextFilter";
import { useEditItem2, useNewItem } from "../../../../toolympus/api/useNewItem";
import { useItemActionWithConfirmation } from "../../../../toolympus/api/useAction";
import { apiFetch } from "../../../../toolympus/api/core";

export interface DecisionBody {
  _id: number;
  title: string;
  title_short: string;
  country?: string | null;
  sort_order: number;
  created_by?: string;
  created_at?: string;
}

export const DecisionBodySchema: Schema = {
  _id: { label: "ID", type: FieldType.number },
  sort_order: { label: "Sort order", type: FieldType.number },
  title: { label: "Title" },
  title_short: { label: "Short title" },
  country: { label: "State", dictionary: "Countries", type: FieldType.dictionarySelect },
  created_by: { label: "Created by", dictionary: "Users", type: FieldType.dictionarySelect },
  created_at: { label: "Created at", type: FieldType.datetime },
}

const ApiPath = "/api/settings/decision-body";

export const useManageDecisionBodies = () => {
  const data = useLoadedData<DecisionBody[]>(ApiPath, []);
  const filter = useTextFilter<DecisionBody>(c => `${c.title} ${c.title_short} ${c._id}`);
  

  const newBody = useNewItem<Omit<DecisionBody, "_id">, DecisionBody>(
    ApiPath,
    {
      title: "",
      title_short: "",
      sort_order: Math.max(...data.data.map(c => c.sort_order), 0) + 1,
    });

  const editBody = useEditItem2<DecisionBody>({
    getApiPath: c => `${ApiPath}/${c._id}`,
  });

  const { formatMessage } = useIntl();

  const removeBody = useItemActionWithConfirmation<DecisionBody, {}>(
    item => apiFetch<DecisionBody>(`${ApiPath}/${item?._id}`, "delete")
      .then(x => {
        data.reload();
        return x;
    }),
    {
      title: formatMessage({ id: "dictionaries.remove_title" }),
      confirmationHint: formatMessage({ id: "dictionaries.remove_hint" }),
    }
  );

  return {
    ...data,
    data: filter.filterData(data.data),
    filter,
    schema: DecisionBodySchema,

    newBody: {
      ...newBody,
      save: () => newBody.save().then(c => { data.reload(); return c; }),
    },
    editBody: {
      ...editBody,
      save: () => editBody.save().then(c => { data.reload(); return c; }),
    },
    removeBody,
  }
}


export type ManageDecisionBodiesData = ReturnType<typeof useManageDecisionBodies>;


export const updateDecisionBody = (id: number, changes: Partial<DecisionBody>) => {
  return apiFetch<DecisionBody>(`${ApiPath}/${id}`, "put", changes);
}
