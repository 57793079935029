import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
    useHistory,
} from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
import { AppWrapper, Sidebar, Menu, Routes, getRoutesToRender, AppContentSimple, DrawerPaperView } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { ExitToApp } from '@mui/icons-material';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { Logo } from './components/Common';
import { DictionariesProvider } from './toolympus/hooks/useDictionaries';
import { MediaLibProvider } from './toolympus/components/medialib';
import { ThemeSettings } from './theme';

const DrawerPaper = styled(DrawerPaperView)`
  & .MuiDrawer-paper {
    background: ${ThemeSettings.colors.text};
  }
    
  & .MuiSvgIcon-root {
      color: ${ThemeSettings.colors.secondary};
  }

  & .Mui-selected {
    & .MuiSvgIcon-root {
      color: ${ThemeSettings.colors.primary};
    } 
  }
`;


const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const { routes, menuItems } = getRoutesToRender(InnerRoutes, user);

    const bottom = (
        <List>
            <ListItem button key='logout' onClick={logout}>
                <ListItemIcon><ExitToApp /></ListItemIcon>
                <ListItemText primary='Log out' />
            </ListItem>
        </List>
    )

    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <MediaLibProvider
            apiPath="/api/media"
            spaceId="cms"
            getFilepath={mf => `/media/${mf.space_id}/${mf.filename}`}>
            <DictionariesProvider apiPath="/api/settings/dictionaries">
              <AppWrapper>
                  <Sidebar
                      top={<Logo />}
                      menu={<Menu menuItems={menuItems} />}
                      bottom={bottom}
                      open={true}
                      onClose={() => { }}
                      drawerPaperProps={{ shadow: true }}
                      drawerPaperComponent={DrawerPaper}
                  />
                  <AppContentSimple fit100Height>
                      <Routes routes={routes} notFoundRedirect="/case" />
                  </AppContentSimple>
              </AppWrapper>
            </DictionariesProvider>
          </MediaLibProvider>
        </UserContext.Provider>
    );
}

export default AppRouter;

