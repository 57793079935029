import React from 'react';
import { DashboardPage } from '../../toolympus/components/Dashboard/DashboardPage';
import { useQuery } from '../../toolympus/hooks/useQueryParamsState';
import { EditDashboards } from '../../toolympus/components/EditDashboards/EditDashboards';
import { useEditDash } from '../../toolympus/components/EditDashboards/useEditDash';

export const DashboardsPage = () => {
   return <DashboardPage defaultDashboard="main" allowChangeDashboard getEditLink={d => `/dashboards?code=${d?.code || ""}`} />
}

export const EditDashboardsPage = () => {
  const defaultCode = useQuery().get("code") || null;
  const data = useEditDash({ defaultDashboardCode: defaultCode });
  return <EditDashboards data={data} />
}
