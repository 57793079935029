import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { Instrument, InstrumentApiPath, useInstrumentSchema } from "./useInstrumentsList"

export const useInstrumentEdit = (id: number) => {
  const data = useCrudItem<Instrument>(`${InstrumentApiPath}/${id}`, {
    defaultValue: {
      _id: -1,
      title: "",
    }
  });

  const schema = useInstrumentSchema();

  return {
    ...data,
    schema,
  };
}
