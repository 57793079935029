import { useState } from "react";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useFieldSorting } from "../../toolympus/hooks/useFieldSorting";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useNewItem } from "../../toolympus/api/useNewItem";

export interface Instrument {
  _id: number;
  title: string;
  
  date_adoption?: string;
  date_effective?: string;
  
  topics?: string[] | null;
  
  depositary_url?: string;
  source_url?: string;

  depositary?: string;
  countries?: string[];

  content?: any;
  description?: any;

  is_published?: boolean;
  
  owner_id?: string;
  created_at?: string;
  created_by?: string;
}

export type NewInstrument = Omit<Instrument, "_id">;

export const InstrumentApiPath = "/api/instrument"

export const useInstrumentSchema = () => {
  const { schema } = useSingleSchema(`${InstrumentApiPath}/uiconfig`);
  return schema;
}

export const useInstrumentsList = () => {
  const [view, setView] = useState<string>("unpublished");
  const sorting = useFieldSorting({});
  const data = useLoadedData<Instrument[]>(`${InstrumentApiPath}?view=${view}&${sorting.queryParameter}`, []);
  const filter = useTextFilter<Instrument>(cr => `${cr.title} ${cr._id}`);

  const newRecord = useNewItem<NewInstrument, Instrument>(InstrumentApiPath, { title: "" });

  return {
    ...data,
    data: filter.filterData(data.data),
    filter,
    view,
    setView,
    sorting,

    newRecord,
  }
}

export type InstrumentListData = ReturnType<typeof useInstrumentsList>;
