import React, { useState } from "react";
import { EditorPlugin } from "../../../toolympus/components/PowerDoc";
import { CustomElement } from "../../../slate";
import { Editor, Element, Transforms } from "slate";
import { CaseRecordSelectionDialog } from "./CaseRecordSelectionDialog";
import { LinkOutlined } from "@mui/icons-material";
import { CaseRecord } from "../../CaseRecords/useCaseRecordsList";
import { ReactEditor } from "slate-react";
import { generateCode } from "../../../toolympus/components/PowerDoc/plugins/common";
import { useInboundLinks } from "./InboundLinksContext";

interface EditingContext {
  element: CustomElement;
  editor: Editor;
}

export const findCurrentElement = (editor: Editor) => {
  const [found] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n),
  });
  
  if(found) {
      return found[0] as Element;
  } else {
      return undefined;
  }
}

export const useInsertCaseRecordLinkPlugin = (): EditorPlugin => {
  const [editingContext, setEditingContext] = useState<EditingContext | null>(null);
  const links = useInboundLinks();

  const openDialog = (editor: Editor, element: CustomElement) => {
      setEditingContext({ editor, element });
  }

  const finishEditing = (cr: CaseRecord) => {
      if(editingContext) {
          // updateMediaFiles(editingContext.editor, editingContext.element, selectedFiles);
          let id = (editingContext.element as any)._id;
          if(!id) {
            id = `${generateCode()}${generateCode()}`;
            const path = ReactEditor.findPath(editingContext.editor, editingContext.element);
            Transforms.setNodes(
              editingContext.editor,
              {
                  _id: id,
              } as any,
              { at: path },
            );
          }
          
          links.addLink(id, "case_record", cr._id.toString(), null);
      }
      setEditingContext(null);
  }

  const dialogs = (<>
    <CaseRecordSelectionDialog
      isOpen={!!editingContext}
      close={() => setEditingContext(null)}
      title="Link a case"
      selectRecord={finishEditing}
    />
    </>);

  return {
    key: "case-record-link",
    commands: [{
        name: "insert-case-record-link",
        invoke: (editor) => {
          const element = findCurrentElement(editor);
          if(element) {
            openDialog(editor, element);
          }
        },
        menu: { section: "insert-item", icon: <LinkOutlined />, label: "Link case record" },
    }],
    dialogs,
  }
}
