import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { CaseRecord, CaseRecordApiPath, useCaseRecordSchema } from "./useCaseRecordsList"

export const useCaseRecordEdit = (id: number) => {
  const data = useCrudItem<CaseRecord>(`${CaseRecordApiPath}/${id}`, {
    defaultValue: {
      _id: -1,
      title: "",
    }
  });

  const schema = useCaseRecordSchema();

  return {
    ...data,
    schema,
  };
}
