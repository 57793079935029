import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MoreActionsMenuButton, PseudoLink2, SimpleList } from '../../../../toolympus/components/primitives';
import { useManageTopics } from './useManageTopics';
import { TopicEditDialog } from './TopicEditDialog';

export const TopicList = () => {
  const data = useManageTopics();
  return (<>
    <SimpleList
      title=""
      data={{
        ...data,
        setFilter: data.filter.setFilter,
        filter: data.filter.filter,
        view: "",
        setView: () => {},
        create: () => { data.newTopic.startEditing(); return Promise.resolve({ _id: -1, title: "", title_short: "", sort_order: -1 }); },
      }}
      searchAuto
      searchAutoFocus
      schema={data.schema}
      fields={[
        ["_id", { width: 40 }],
        ["title"],
        ["sort_order"],
      ]}
      linkFields={[]}
      getLinkValue={() => ""}
      fieldElement={f => {
        switch(f) {
          case "_id":
          case "title":
            return (row,schema,orig) => <PseudoLink2 border onClick={() => data.editTopic.startEditing(row)}>{orig}</PseudoLink2>
        }
      }}
      rowButtons={row => (
        <MoreActionsMenuButton actions={[["remove", <FormattedMessage id="common.delete" />, () => data.removeTopic.run(row)]]} />
      )}
      />

    <TopicEditDialog
      {...data.newTopic}
      isOpen={data.newTopic.isEditing}
      close={data.newTopic.cancel}
      isCreate
      />
    <TopicEditDialog
      {...data.editTopic}
      isOpen={data.editTopic.isEditing}
      close={data.editTopic.cancel}
      />
  </>);
}
