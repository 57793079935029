import React from 'react';
import { Schema } from '../../toolympus/hooks/useSchema';
import { NewItemProps } from '../../toolympus/api/useNewItem';
import { CaseRecord, NewCaseRecord } from './useCaseRecordsList';
import { FormGrid, SimpleDialog } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';

interface Props extends NewItemProps<NewCaseRecord, CaseRecord> {
  schema: Schema;
}

export const CreateCaseRecordDialog = (props: Props) => {
  const { isEditing, cancel, errors, item, update, save, schema } = props;
  return (
    <SimpleDialog
      isOpen={isEditing}
      close={cancel}
      dialogTitle="New case"
      save={() => save()}>
      {item && <FormGrid columns="1fr">
        <FormControlsForFields
          fields={[
            ["title"],
            ["case_date"],
            ["country"],
          ]}
          data={item}
          onChange={(o,c) => update(c)}
          errors={errors}
          schema={schema}
          />
      </FormGrid>}
    </SimpleDialog>
  );
}

