import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { InboundLinksData, useInboundLinks, useInboundLinksData } from '../../Administration/Editor/InboundLinksContext';
import { Typography } from '@mui/material';
import { LinkBlip } from './LinksBlips';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 6px;  
`;

interface PropsUnbound {
  data: Pick<InboundLinksData, "links">;
  label?: ReactNode;
}

export const InboundLinksUnbound = (props: PropsUnbound) => {
  const { data } = props;

  return (
    <Wrapper>
      {!!props.label && <Typography variant="caption" color="textSecondary">{props.label}</Typography>}
      {data.links.map(l => (
        <LinkBlip
          key={`${l.that_record_type}_${l.that_record_id}`}
          link={l}
          />
      ))}
    </Wrapper>
  );
}


interface Props {
  recordType: string;
  recordId: string;
  label?: ReactNode;
}

export const InboundLinksList = (props: Props) => {
  const data = useInboundLinksData({ this_record_type: props.recordType, this_record_id: props.recordId });

  return <InboundLinksUnbound {...props} data={data} />
}

export const InboundLinksListOnCtx = (props: Pick<Props, "label">) => {
  const data = useInboundLinks();

  return <InboundLinksUnbound {...props} data={data} />
}
