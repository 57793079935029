import React from 'react';
import styled from '@emotion/styled';
import { DisplayProps } from '../schemed/FormControlProps';
import { getPowerDocText } from './PowerDocText';

export const ContentWrapper = styled.div`
  white-space: pre-line;
`;

export const SimplePowerDocViewer = (props: DisplayProps) => {
  return (
    <ContentWrapper>{getPowerDocText(props.row[props.field])}</ContentWrapper>
  );
}
