import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DecisionBody, updateDecisionBody, useManageDecisionBodies } from './useManageDecisionBodies';
import { MoreActionsMenuButton, PseudoLink2, SimpleList } from '../../../../toolympus/components/primitives';
import { DecisionBodyEditDialog } from './DecisionBodyEditDialog';
import { Button, Typography } from '@mui/material';
import { Sort } from '@mui/icons-material';
import { ReorderItemsDialog, useReorderItemsBySortorder } from '../../../../toolympus/components/primitives/ReorderItems';

const DecisionBodyReordering = (props: { item: DecisionBody }) => {
  return <Typography>{props.item.title}</Typography>
}

export const DecisionBodyList = () => {
  const data = useManageDecisionBodies();
  
  const reorderData = useReorderItemsBySortorder<DecisionBody>({
    updateItemOrder: (item, order) => {
      if(item.sort_order !== order) {
        return updateDecisionBody(item._id, { sort_order: order });
      } else {
        return Promise.resolve(item);
      }
    },
    onSave: () => data.reload(),
  });

  return (<>
    <SimpleList
      title=""
      data={{
        ...data,
        setFilter: data.filter.setFilter,
        filter: data.filter.filter,
        view: "",
        setView: () => {},
        create: () => { data.newBody.startEditing(); return Promise.resolve({ _id: -1, title: "", title_short: "", sort_order: -1 }); },
      }}
      searchAuto
      searchAutoFocus
      schema={data.schema}
      fields={[
        ["_id", { width: 40 }],
        ["title"],
        ["title_short"],
        ["country"],
        ["sort_order"],
      ]}
      linkFields={[]}
      getLinkValue={() => ""}
      fieldElement={f => {
        switch(f) {
          case "_id":
          case "title":
          case "title_middle":
          case "title_short":
            return (row,schema,orig) => <PseudoLink2 border onClick={() => data.editBody.startEditing(row)}>{orig}</PseudoLink2>
        }
      }}
      rowButtons={row => (
        <MoreActionsMenuButton actions={[["remove", <FormattedMessage id="common.delete" />, () => data.removeBody.run(row)]]} />
      )}
      headerActions={<>
        <Button size="small" color="primary" startIcon={<Sort />} onClick={() => reorderData.startEditing(data.data)}>reorder</Button>
      </>}
      />

    <DecisionBodyEditDialog
      {...data.newBody}
      isOpen={data.newBody.isEditing}
      close={data.newBody.cancel}
      isCreate
      />
    <DecisionBodyEditDialog
      {...data.editBody}
      isOpen={data.editBody.isEditing}
      close={data.editBody.cancel}
      />

    <ReorderItemsDialog
      data={reorderData}
      title="Reorder organs"
      itemKey={o => o._id.toString()}
      ItemComponent={DecisionBodyReordering}
      />
  </>);
}
