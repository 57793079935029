import React from 'react';
import { LoginForm } from '../../toolympus/components/auth';
import { useLoginForm } from '../../toolympus/hooks/auth';
import { useReturnUrl } from '../../toolympus/hooks/auth/useReturnUrl';
import { PublicPageWrapper } from '../Common/PublicPageWrapper';
import { Typography } from '@mui/material';
import { LoadingIndicator } from '../../toolympus/components/primitives';

export const LoginContainer = () => {
    const returnUrl = useReturnUrl();
    const signupData = useLoginForm("/api/login", returnUrl.fromParam || "/");
    
    return <PublicPageWrapper>
        {!signupData.isSaving && <Typography variant="h2">Sign in</Typography>}
        {!signupData.isSaving && <LoginForm loginData={signupData} />}
        {signupData.isSaving && <LoadingIndicator />}
    </PublicPageWrapper>;
}