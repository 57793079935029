import React from 'react';
import { Schema } from '../../toolympus/hooks/useSchema';
import { NewItemProps } from '../../toolympus/api/useNewItem';
import { Instrument, NewInstrument } from './useInstrumentsList';
import { FormGrid, SimpleDialog } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';

interface Props extends NewItemProps<NewInstrument, Instrument> {
  schema: Schema;
}

export const CreateInstrumentDialog = (props: Props) => {
  const { isEditing, cancel, errors, item, update, save, schema } = props;
  return (
    <SimpleDialog
      isOpen={isEditing}
      close={cancel}
      dialogTitle="New instrument"
      save={() => save()}>
      {item && <FormGrid columns="1fr">
        <FormControlsForFields
          fields={[
            ["title"],
            ["date_adoption"],
            ["date_effective"],
          ]}
          data={item}
          onChange={(o,c) => update(c)}
          errors={errors}
          schema={schema}
          />
      </FormGrid>}
    </SimpleDialog>
  );
}

