import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Logo } from '.';
import { ThemeSettings } from '../../theme';

const Wrapper = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 60px;
    height: 100svh;
    box-sizing: border-box;

    position: relative;
    overflow: hidden;
`;

const BlackBlock = styled.div`
  background: ${ThemeSettings.colors.background.dark};
  flex: 1 0 auto;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: ${ThemeSettings.geometry.shadow.panel} ${ThemeSettings.colors.shadow.normal};
`;

const WrapperInner = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
    width: 400px;
    gap: 0;
    z-index: 100;

    & ${BlackBlock} {
      &:first-child {
        margin-bottom: 45px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: flex-end;

        border-bottom-left-radius: ${ThemeSettings.geometry.border.radius.panel}px;
        border-bottom-right-radius: ${ThemeSettings.geometry.border.radius.panel}px;
        
        & img {
            width: 100%;
        }
      }

      &:last-child {
        margin-top: 45px;

        border-top-left-radius: ${ThemeSettings.geometry.border.radius.panel}px;
        border-top-right-radius: ${ThemeSettings.geometry.border.radius.panel}px;
      }
    }

    & h2 {
      font-size: 1.6rem;
      font-weight: 400;
      margin: 0 0 0.5rem;
      letter-spacing: 0.03em;
      text-transform: lowercase;
    }

`;

const Honey = styled.img`
  position: absolute;
  top: -10%;
  left: 0;
  max-width: calc(100% - 500px);
  height: 120%;
  filter: drop-shadow(5px 5px 15px #00000040);
  z-index: 5;
`;

const Gradient1 = styled.div`
  position: fixed;
  top: -80vh;
  left: -128vw;
  width: 200vw;
  height: 200vh;
  background: radial-gradient(at 50% 50%, ${ThemeSettings.colors.primaryShade.s4} 0, ${ThemeSettings.colors.primaryShade.s4} 12%, ${ThemeSettings.colors.primaryShade.s0} 60%);
  z-index: 1;
`;
const Gradient2 = styled.div`
  position: fixed;
  top: 50vh;
  left: -98vw;
  width: 200vw;
  height: 200vh;
  background: radial-gradient(at 50% 50%, ${ThemeSettings.colors.secondaryShade.s4} 0, ${ThemeSettings.colors.secondaryShade.s4} 12%, ${ThemeSettings.colors.secondaryShade.s0} 60%);
  z-index: 1;
`;


export const PublicPageWrapper = (props: { children?: ReactNode }) => {
    return (<>
        <Wrapper>
          <Gradient1 />
          <Gradient2 />
          <Honey src="/img/honeycomb_new2.png" />
          <WrapperInner>
            <BlackBlock>
              <Logo version={1} />
            </BlackBlock>
            {props.children}
            <BlackBlock />
          </WrapperInner>
        </Wrapper>
      </>
      )
}