import React from "react";
import { PowerEditorBaseProps, PowerEditorBase } from "../../toolympus/components/PowerDoc";
import { ColumnsPlugin } from "../../toolympus/components/PowerDoc/plugins/Columns";
import { CustomFieldsBlockPlugin } from "../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock";
import { ExpanderPlugin } from "../../toolympus/components/PowerDoc/plugins/Expander";
import { useMediaFilesPlugin } from "../../toolympus/components/PowerDoc/plugins/MediaFiles";
import { PanelPlugin } from "../../toolympus/components/PowerDoc/plugins/Panel/PanelPlugin";
import { ScriptPlugin } from "../../toolympus/components/PowerDoc/plugins/Script";
import { TablesPlugin } from "../../toolympus/components/PowerDoc/plugins/Tables";
import { TilesPlugin } from "../../toolympus/components/PowerDoc/plugins/Tiles";
import { createWrappedPowerEditor } from "../../toolympus/components/PowerDoc/PowerEditorSettingsContext";
import { SettingsBlockPlugin } from "../../toolympus/components/PowerDoc/plugins/SettingsBlock";
import { useInsertCaseRecordLinkPlugin } from "./Editor/InsertCaseRecordLinkPlugin";
import { BlockElement } from "./Editor/BlockElement";

export const BiodevEditorUnwrapped = (props: PowerEditorBaseProps) => {
  const mediaFilesPlugin = useMediaFilesPlugin();
  const caseRecordsLinksPlugin = useInsertCaseRecordLinkPlugin();

  return (
      <PowerEditorBase
          {...props}
          customRenderElement={BlockElement}
          plugins={[
              mediaFilesPlugin,
              CustomFieldsBlockPlugin,
              SettingsBlockPlugin,
              TilesPlugin,
              ColumnsPlugin,
              PanelPlugin,
              ScriptPlugin,
              ExpanderPlugin,
              TablesPlugin,
              caseRecordsLinksPlugin,
          ]}
          />
  );
}

export const BiodevEditor = createWrappedPowerEditor({
  EditorComponent: BiodevEditorUnwrapped
});
