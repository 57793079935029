import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';

export const ThemeSettings = {
  colors: {
    primary: "#5493f7",
    secondary: "#84c33e",
    text: "#03151a",

    primaryShade: {
      s0: "#5493f700",
      s4: "#5493f780",
    },
    secondaryShade: {
      s0: "#84c33e00",
      s4: "#84c33e80",
    },
    
    background: {
      normal: "#ffffff",
      dark: "#03151a",
    },

    shadow: {
      normal: "#03151a80",
    },
  },

  geometry: {
    border: {
      radius: {
        panel: 24,
        controls: 12,
      },
    },

    shadow: {
      panel: "2px 2px 10px -2px",
      panel_light: "1px 1.5px 18px -4px",
      panel_glow: "0px -2px 25px -5px",
    },
  },
}

export const theme = createTheme({
    palette: {
        primary: {
            main: ThemeSettings.colors.primary,
        },
        secondary: {
            main: ThemeSettings.colors.secondary,
        },
        text: {
          primary: ThemeSettings.colors.text,
        },
    },

    typography: {
      fontFamily: "InterTight",
    },

    shape: {
      borderRadius: ThemeSettings.geometry.border.radius.controls,
    },

    components: {
      ...BaseMuiThemeComponentsSettings,
    },
});
